import React from "react"
import { withTranslation } from "react-i18next"
import "./index.scss"

const GeneralIntro = props => {
  const { i18n, t } = props
  return (
    <div className="general-intro">
      <div className="general-question">
        <span>{t("src.pages.eventAppPage.general.question")}</span>
      </div>
      <div className="general-content">
        <p>
          {i18n.language === "en" ? (
            <>
              <span>{t("src.pages.eventAppPage.general.descriptions1")}</span>
              {t("src.pages.eventAppPage.general.descriptions2")}
              {t("src.pages.eventAppPage.general.descriptions3")}
              {t("src.pages.eventAppPage.general.descriptions4")}
              <span>{t("src.pages.eventAppPage.general.descriptions5")}</span>
            </>
          ) : (
            <>
              {t("src.pages.eventAppPage.general.descriptions1")}
              <span>{t("src.pages.eventAppPage.general.descriptions2")}</span>
              {t("src.pages.eventAppPage.general.descriptions3")}
              <span>{t("src.pages.eventAppPage.general.descriptions4")}</span>
              {t("src.pages.eventAppPage.general.descriptions5")}
            </>
          )}
        </p>
      </div>
      {/* <div className="button-tryourapp">
        <a className="btn btn-primary" href="/">
          Try Our App
        </a>
      </div> */}
    </div>
  )
}

export default withTranslation()(GeneralIntro)
