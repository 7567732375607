import React from "react"
import { withTranslation } from "react-i18next"
import "./index.scss"

const Introduce = props => {
  const { t } = props
  return (
    <div className="coverIntroEventApp">
      <div className="eventBigBG">
        <div className="coverBg1">
          <div className="container">
            <div className="coverSpan">
              <div className="content">
                <span>{t("src.pages.eventAppPage.introduce")}</span>
              </div>
            </div>
            {/* <div className="button-tryourapp">
              <a className="btn btn-primary" href="/">
                Try Our App
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Introduce)
