import React from "react"
import { withTranslation } from "react-i18next"
import iconShakeHand from "../../../../assets/img/media/solution/eventApp/handshake.svg"
import iconAffordable from "../../../../assets/img/media/solution/eventApp/affordable.svg"
import iconChat from "../../../../assets/img/media/solution/eventApp/chat.svg"
import iconSale from "../../../../assets/img/media/solution/eventApp/conection.svg"
import iconSimple from "../../../../assets/img/media/solution/eventApp/target.svg"
import "./index.scss"

const Achievement = props => {
  const { t } = props
  return (
    <div className="event-achievements">
      <div className="coverAchievement">
        <div className="AchievementBigBG">
          <div className="coverBg1">
            <div className="statistical">
              <div className="stats-box">
                <div className="stats-number">100K+</div>
                <div className="box-footer">
                  <span>
                    {t("src.pages.eventAppPage.achievement.downloads")}
                  </span>
                </div>
              </div>
              <div className="stats-box">
                <div className="stats-number">750+</div>
                <div className="box-footer">
                  <span>{t("src.pages.eventAppPage.achievement.events")}</span>
                </div>
              </div>
              <div className="stats-box">
                <div className="stats-number">500K+</div>
                <div className="box-footer">
                  <span>
                    {t("src.pages.eventAppPage.achievement.attendees")}
                  </span>
                </div>
              </div>
              <div className="stats-box">
                <div className="stats-number">7M+</div>
                <div className="box-footer">
                  <span>
                    {t("src.pages.eventAppPage.achievement.engagements")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefit">
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconSimple} alt="Simplification" />
          </div>
          <div className="benefit-content">
            {t("src.pages.eventAppPage.achievement.benefit.simplification")}
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconAffordable} alt="Cost Reduction" />
          </div>
          <div className="benefit-content">
            {t("src.pages.eventAppPage.achievement.benefit.cost")}
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconSale} alt="Communication" />
          </div>
          <div className="benefit-content">
            {t("src.pages.eventAppPage.achievement.benefit.communication")}
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconChat} alt="Event ROI Improvement" />
          </div>
          <div className="benefit-content">
            {t("src.pages.eventAppPage.achievement.benefit.ROI")}
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">
            <img src={iconShakeHand} alt="Partnership Growing" />
          </div>
          <div className="benefit-content">
            {t("src.pages.eventAppPage.achievement.benefit.partnership")}
          </div>
        </div>
      </div>
      {/* <div className="button-tryourapp">
        <a className="btn btn-primary" href="/">
          Try Our App
        </a>
      </div> */}
    </div>
  )
}

export default withTranslation()(Achievement)
