import React from "react"
import EventAppPage from "../components/solution/eventApp/index"

const EventApp = () => {
    return (
        <>
            <EventAppPage />
        </>
    )
}

export default EventApp
