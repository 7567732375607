import React from "react"
import { withTranslation } from "react-i18next"
import iconStayConnected from "../../../../assets/img/media/solution/eventApp/Union.svg"
import iconManager from "../../../../assets/img/media/solution/eventApp/Union (1).svg"
import iconUpdate from "../../../../assets/img/media/solution/eventApp/Union (2).svg"
import iconAttendees from "../../../../assets/img/media/solution/eventApp/Union (3).svg"
import iconRelationship from "../../../../assets/img/media/solution/eventApp/Union (4).svg"
import "./index.scss"

const MainFunction = props => {
  const { t } = props
  return (
    <div className="main-functions">
      <div className="coverMainFunction">
        <div className="mainBigBG">
          <div className="coverBG">
            <div className="coverP">
              <p>
                {t("src.pages.eventAppPage.mainFunction.descriptions1")}
                <span>
                  {t("src.pages.eventAppPage.mainFunction.descriptions2")}
                </span>
                {t("src.pages.eventAppPage.mainFunction.descriptions3")}
                <span>
                  {t("src.pages.eventAppPage.mainFunction.descriptions4")}
                </span>
                {t("src.pages.eventAppPage.mainFunction.descriptions5")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="functions">
        <div className="cover-list-func">
          <div className="list-functions">
            <div className="item">
              <div className="item-icon">
                <img src={iconStayConnected} alt="Stay connected" />
              </div>
              <div className="item-content">
                <span>
                  {t("src.pages.eventAppPage.mainFunction.connected")}
                </span>
              </div>
            </div>
            <div className="item">
              <div className="item-icon">
                <img src={iconManager} alt="Comprehensively Manage" />
              </div>
              <div className="item-content">
                <span>
                  {t("src.pages.eventAppPage.mainFunction.comprehensively")}
                </span>
              </div>
            </div>
            <div className="item">
              <div className="item-icon">
                <img src={iconUpdate} alt="Constantly Update" />
              </div>
              <div className="item-content">
                <span>
                  {t("src.pages.eventAppPage.mainFunction.constantly")}
                </span>
              </div>
            </div>

            <div className="item">
              <div className="item-icon">
                <img src={iconAttendees} alt="Collect attendees’ insights" />
              </div>
              <div className="item-content">
                <span>{t("src.pages.eventAppPage.mainFunction.collect")}</span>
              </div>
            </div>
            <div className="item">
              <div className="item-icon">
                <img src={iconRelationship} alt="Keep The Relationship Going" />
              </div>
              <div className="item-content">
                <span>
                  {t("src.pages.eventAppPage.mainFunction.relationship")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(MainFunction)
