import React from "react"
import { withTranslation } from "react-i18next"
import "./index.scss"

const Question = props => {
  const { t } = props
  return (
    <div className="event-question">
      <div className="content">
        <span>{t("src.pages.eventAppPage.customersQuotes")}</span>
      </div>
    </div>
  )
}

export default withTranslation()(Question)
