import React, { Component } from "react"
import Layout from "../../layout/layout.higherComponent"
import Head from "../../head"
import Introduce from "./introduce"
import GeneralIntro from "./generalIntro"
import Question from "./question"
import Achievement from "./achievement"
import MainFunction from "./mainFunction"
import CostumerQuote from "./customerQuote"
import "./index.scss"

class EventAppPage extends Component {
  render() {
    return (
      <>
        <Layout displayHeader={true}>
          <Head title="Event app" />
          <Introduce />
          <GeneralIntro />
          <Achievement />
          <MainFunction />
          <Question />
          <CostumerQuote />
        </Layout>
      </>
    )
  }
}

export default EventAppPage
