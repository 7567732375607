import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import Card from "./card"
// import data from "./data"
import iconPrev from "../../../../assets/img/media/solution/eventApp/prev.svg"
import iconNext from "../../../../assets/img/media/solution/eventApp/next.svg"
import "./index.scss"

class CustomerQuote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      properties: null,
      property: null,
      viewWidth: null
    }
  }

  componentWillMount() {
    this.setState({
      properties: this.props.t("src.pages.eventAppPage.customersQuotesData"),
      property: this.props.t("src.pages.eventAppPage.customersQuotesData")[1],
      viewWidth: null
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.t !== prevProps.t) {
      this.setState({
        properties: this.props.t("src.pages.eventAppPage.customersQuotesData"),
        property: this.props.t("src.pages.eventAppPage.customersQuotesData")[
          this.state.property.index || 1
        ],
        viewWidth: this.state.viewWidth
      })
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({
      properties: this.state.properties,
      property: this.state.property,
      viewWidth: window.innerWidth
    })
  }

  nextProperty = () => {
    const newIndex = this.state.property.index + 1
    this.setState({
      property: this.state.properties[newIndex]
    })
  }

  prevProperty = () => {
    const newIndex = this.state.property.index - 1
    this.setState({
      property: this.state.properties[newIndex]
    })
  }

  render() {
    const { properties, property } = this.state
    return (
      <div className="main-costumer-quotes">
        <div className="page">
          <div
            className="prev-property"
            onClick={() => this.prevProperty()}
            style={{
              pointerEvents: property.index === 0 ? "none" : "auto",
              opacity: property.index === 0 ? 0 : 1
            }}
          >
            <img src={iconPrev} alt="previous" />
          </div>
          <div
            className="next-property"
            onClick={() => this.nextProperty()}
            style={{
              pointerEvents:
                property.index === this.state.properties.length - 1
                  ? "none"
                  : "auto",
              opacity:
                property.index === this.state.properties.length - 1 ? 0 : 1
            }}
          >
            <img src={iconNext} alt="next" />
          </div>
          <div className="col">
            <div className={`cards-slider active-slide-${property.index}`}>
              <div
                className="cards-slider-wrapper"
                style={{
                  transform:
                    this.state.viewWidth >= 1024
                      ? `translateX(-${property.index *
                          (100 / properties.length) -
                          property.index * 2}%)`
                      : this.state.viewWidth >= 768
                      ? `translateX(-${property.index *
                          (100 / properties.length) -
                          property.index * 1}%)`
                      : `translateX(-${property.index *
                          (100 / properties.length) -
                          property.index * 0}%)`
                  //1024+px: -property.index*2
                  //768px : -property.index*1
                  //375px: -property.index*0
                }}
              >
                {properties.map((property, index) => (
                  <Card key={index} property={property} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="button-tryourapp">
          <a className="btn btn-primary" href="/">
            Try Our App
          </a>
        </div> */}
      </div>
    )
  }
}

export default withTranslation()(CustomerQuote)
