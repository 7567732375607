import React from "react"
import "./index.scss"
const Card = ({ property }) => {
  const { index, name, company, comment } = property
  return (
    <div id={`card-${index}`} className="card">
      <div className="customer-information">
        <div className="avatar">
          <img src={null} alt={name} />
        </div>
        <span className="name">
          {name} – {company}
        </span>
      </div>
      <div className="details">
        <p>{comment}</p>
      </div>
    </div>
  )
}

export default Card
